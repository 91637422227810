
const botMaskLinks = document.querySelectorAll('a.nospam');
if(botMaskLinks.length > 0){

    botMaskLinks.forEach((link) => {

        const full = link.dataset.nospam
            + '@outsourcecall'
            + `.${link.dataset.tld}`;

        link.textContent = full;

        link.setAttribute('href', `mailto:${full}`);
    })

}
