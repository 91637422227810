import axios from "axios";

(function (){

    let form = document.querySelector('.cs-subscribe-form');

    if (form === null) return;

    let button = form.querySelector('button[type="submit"]'),
        buttonText = button.innerHTML,
        input = form.querySelector('.form-control'),
        status = form.querySelector('.cs-subscribe-status');

    form.addEventListener('submit', function(e) {
        if (e) e.preventDefault();

        const that = this;

        if(typeof grecaptcha !== 'undefined'){
            /*grecaptcha.ready(() => {
                grecaptcha.execute('6LeJy8McAAAAADxGnF5LUbC4eLa6Ghmgq-aBs9-4', {action: 'submit'}).then((token) => {
                    register(that, button, input, buttonText, status, token);
                });
            })*/

            const recaptchaBox = document.querySelector('.g-recaptcha-response');
            const token = recaptchaBox.value;
            register(that, button, input, buttonText, status, token);
        } else {
            status.innerHTML = 'reCaptcha was not loaded. Please disable ads/tracker blockers and refresh page'
        }
    });


    let viewSwitch = (target) => {

        let targetView = document.querySelector(target),
            targetParent = targetView.parentNode,
            siblingViews = targetParent.querySelectorAll('.cs-view');

        for (let n = 0; n < siblingViews.length; n++) {
            siblingViews[n].classList.add('d-none');
        }

        targetView.classList.remove('d-none');
    }

    let register = (form, button, input, buttonText, status, reCaptchaToken) => {
        button.innerHTML = 'Sending...';
        button.disabled = true;

        let url = '/api/send-form';
        let data = {};
        let inputs = document.querySelectorAll('input.form-control');
        for (let n = 0; n < inputs.length; n++) {
            const input = inputs[n];
            input.classList.remove('is-invalid');

            data[`${input.name}`] = input.value;
        }

        data['message'] = document.getElementById('gq-details').value;
        data['g-recaptcha-response'] = reCaptchaToken;

        axios.post(url, data)
            .then(function (response) {
                // Display content and apply styling to response message conditionally
                if(response.data.result == 'success') {

                    viewSwitch('#modal-thank-you-view');

                    input.classList.remove('is-invalid');
                    input.classList.add('is-valid');
                    status.classList.remove('text-danger');
                    status.classList.add('text-success');
                    status.innerHTML = response.data.msg;

                } else {
                    input.classList.remove('is-valid');
                    input.classList.add('is-invalid');
                    status.classList.remove('text-success');
                    status.classList.add('text-danger');
                    status.innerHTML = response.data.msg;

                }
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/

                    let errorsAllArr = [];

                    let responseObj = error.response.data;
                    console.log(error.response)
                    console.log(responseObj)

                    for (const [fieldName, errors] of Object.entries(responseObj)) {
                        let field = form.querySelector(`input.form-control[name="${fieldName}"]`);
                        if(! field){
                            field = form.querySelector(`textarea[name="${fieldName}"]`);
                        }

                       // console.log(fieldName, field)

                        if(fieldName === 'g-recaptcha-response'){
                            grecaptcha.reset()
                        }

                        if(field){
                            field.classList.remove('is-valid');
                            field.classList.add('is-invalid');
                            // let fieldTooltip = form.querySelector(`.invalid-tooltip[data-valid-for="${fieldName}"]`);
                            let fieldTooltip = field.parentElement.querySelector(`.invalid-tooltip`);
                            if(fieldTooltip){
                                const errKeyFirst = Object.keys(errors).pop();
                                fieldTooltip.innerText = errKeyFirst;

                            }
                        }

                        /*let fErr = errorsArr.join('');
                        errorsAllArr.push(fErr)*/
                    }

                    status.innerHTML = 'Please, check all fields';//errorsAllArr.join('<br>');//error.response.statusText

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    status.innerHTML = 'No response was received. Check network';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    status.innerHTML = 'Cannot send message. Undefined error';
                }
            })
            .finally(() => {
                button.innerHTML = buttonText;
                button.disabled = false;
            });

        return;
    }

}())
